@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap');
$gray: #B4B4B4;
$blue-dark: #025184;
:root {
  --gray: rgba(24, 129, 225, 0.4);
}

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

body {
  font-family: 'Lexend', sans-serif;
}


/* Firefox */

* {
  scrollbar-width: auto;
  // scrollbar-color: #C8A366 #ffffff;
}


/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbd5d5c9;
  border-radius: 10px;
  border: 2px solid #FFFFFF;
  &:hover {
    background-color: #767FFE;
  }
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.text-gray {
  color: #8C8C8C;
}

.text-green {
  color: #0BCC41;
}

.text-red {
  color: #E31010;
}

.text-lightblue {
  color: #407BFF;
}

.bg-gray {
  background: #FCFCFC !important;
}

.bg-lightgreen {
  background: #E2FFEA;
}

.bg-blue {
  background: #025184;
}

.bg-lightblue {
  background: #2d98df;
}

.bg-green {
  background: #0BCC41;
}

.bg-pink {
  background: #FF4AA1;
}

.bg-lightpink {
  background: #FFF1F1;
}

.fs-9 {
  font-size: 9px;
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.w10 {
  width: 10%;
}

.w22 {
  width: 22%;
  max-width: 22%;
}

.w90 {
  width: 90%;
}

.w-200px {
  width: 200px;
}

.wh-20px {
  width: 20px !important;
  height: 20px !important;
}

.wh-40px {
  width: 40px !important;
  height: 40px !important;
}

.text-blue {
  color: #112BC2;
}

.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  background: #EBEEFF;
  z-index: 20;
  .btn-logout {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: none;
    &:hover {
      color: #2d98df;
    }
  }
}

.wrapper-box {
  padding-top: calc(52px + 30px);
}

.content-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 40px;
}

.wrapper {
  min-height: 100vh;
  position: relative;
  z-index: 18;
}

.bg-gray {
  background: #e9e5e6;
}

.grd-btn {
  flex: 1 1 auto;
  // padding: 30px;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
}

.grd-btn:hover {
  background-position: right center !important;
}

.grd-btn {
  background-image: linear-gradient(to right, #0E1F6E 0%, #246b9a 51%, #112EDB 100%);
  // background: linear-gradient(90deg, #014F82 0%, #36BCCF 100%);
  // padding: 10px 20px;
  border: none;
  border: 1px solid #112EDB;
}

.grdline-btn {
  // flex: 1 1 auto;
  padding: 10px 40px;
  transition: 0.5s;
  color: black;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  border: none;
  background: #FFFFFF;
  border: 1px solid #2A2A2A;
}

.grdline-btn:hover {
  background-position: right center !important;
}

.grdline-btn:hover {
  background-image: linear-gradient(to right, #0E1F6E 0%, #246b9a 51%, #112EDB 100%);
  border: 1px solid #112EDB;
  color: #FAFAFA;
}

.signin-box {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
}

.signin-left {
  background: #FFFFFF;
  height: 100%;
  min-height: 100%;
  // max-height: 100vh;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.signin-right {
  background: #F9F9F9;
  height: 100%;
  width: 100%;
  padding: 80px 80px 40px 80px !important;
  .btn-box {
    background: #EEEEEE;
    border-radius: 6px;
    padding: 8px;
    button {
      background: transparent;
      border: none;
      padding: 8px;
      border-radius: 5px;
      &:hover,
       :active {
        background: #FFFFFF;
      }
    }
  }
  .anchor {
    a {
      text-decoration: none;
      color: #055687;
    }
    .line {
      text-decoration: underline;
    }
  }
  form {
    input {
      box-shadow: 0px 0px 8px 0px #00000040;
    }
     ::-ms-input-placeholder {
      /* Edge 12-18 */
      color: #E0E0E0 !important;
    }
     ::placeholder {
      color: #E0E0E0 !important;
    }
  }
}

::placeholder {
  color: #2A2A2A !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #2A2A2A;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #2A2A2A;
}

form {
  input {
    background: transparent;
    border: none;
    outline: none;
    &:focus {
      border: none;
      background: none;
    }
  }
}

.btn-card {
  background: white;
  display: flex;
  text-align: center;
  justify-content: center;
  border: 1px solid #D2D2D2;
  box-shadow: 0px 4px 20px 0px #0000001A;
  border-radius: 20px;
  transition: 0.5s;
  font-family: M PLUS 1;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  &:hover {
    background-image: linear-gradient(to right, #0E1F6E 0%, #246b9a 51%, #112EDB 100%);
    color: #fff;
  }
  .btn-arrow {
    position: absolute;
    top: 25%;
    right: 40px;
    font-size: 40px;
  }
}

.btn-back {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  &:hover {
    color: #2d98df;
  }
}

.btn-nrml {
  border: 1px solid #000;
  background: none;
  &:hover {
    background-image: linear-gradient(to right, #0E1F6E 0%, #246b9a 51%, #112EDB 100%);
    color: #fff;
    border: 1px solid transparent;
  }
  &:disabled {
    pointer-events: none;
  }
}

.normal-tbl {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    td,
    th {
      text-align: start;
      padding: 12px 10px;
    }
    th {
      background-color: #F6F6F6;
    }
    td {
      padding: 10px 10px;
    }
  }
  tr:nth-child(odd) {
    background-color: #FBFBFB;
    border: 1px solid #E2E2E2;
  }
}

// =====tab btn
.tabs-row {
  .nav-pills {
    display: flex;
    justify-content: space-between;
    .nav-item {
      background: #FFFFFF;
      width: 48%;
      .nav-link {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

.nav-item .nav-link {
  background: none;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
  color: #2A2A2A !important;
  padding: 8px 20px !important;
  width: 100%;
  &:hover {
    background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%) !important;
    border: 1px solid #112ED8 !important;
    color: #FFFFFF !important;
    transition: 400ms;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #FFFFFF !important;
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
  border: 1px solid #112ED8 !important;
  border-radius: 29px;
}

.chip {
  align-items: center;
  background: #112ED8 !important;
  border-radius: 6px !important;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
}

.select-drop {
  width: 200px;
  max-width: 250px;
}

.searchWrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 22px;
  padding: 5px;
  position: relative;
  width: 300px !important;
}

.list-boxes {
  background: #F3F6FF;
  border-radius: 20px;
  ul {
    list-style-type: none;
    padding: 10px 0;
    margin: 0;
    li {
      padding: 10px 20px;
      font-family: Lexend;
      font-size: 20px;
      font-weight: 400;
      border-bottom: 1px solid #D4D4D4;
    }
    li:last-child {
      border-bottom: none;
    }
  }
}

.btn-tick {
  background: none;
  padding: 0;
  border: none;
  outline: none;
  color: #fff;
  &:hover {
    color: #112BC2 !important;
  }
}

.input-box {
  input {
    border: none;
    outline: none;
    border-bottom: 0.5px solid #ccc !important;
    padding: 10px 0;
  }
   ::placeholder {
    color: #2a2a2a !important;
  }
  .react-tel-input {
    border-radius: 0px !important;
    .form-control {
      position: relative;
      font-size: 14px;
      letter-spacing: .01rem;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-left: 48px;
      margin-left: 0;
      background: #FFFFFF;
      border: none !important;
      border-bottom: 0.5px solid #ccc !important;
      border-radius: 0px !important;
      line-height: 25px;
      height: 35px;
      width: 300px;
      outline: none;
      width: 100%;
      &:focus {
        border: none !important;
        box-shadow: none !important;
        border-bottom: 0.5px solid #ccc !important;
      }
    }
    .selected-flag {
      outline: none;
      position: relative;
      width: 38px;
      height: 100%;
      padding: 0 0 0 8px;
      border-radius: 3px 0 0 3px;
      border: none !important;
    }
  }
}

.react-tel-input {
  .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: none !important;
    border-right: 1px solid #cacaca !important;
    border-radius: 3px 0 0 3px;
    &:hover {
      border-bottom: 0.5px solid #ccc !important;
    }
  }
}

// accordion button in center align
.accordion-header {
  position: relative;
  margin-bottom: 20px;
  .accordion-button {
    background: #F3F6FF !important;
    font-family: Lexend;
    font-size: 20px;
    font-weight: 400;
  }
  .accor-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
    transform: translate(-50%, -50%)
  }
  .accor-assign {
    position: absolute;
    top: 50%;
    left: 30%;
    z-index: 2;
    background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
    transform: translate(-50%, -50%)
  }
}

.accor-btn {
  border: none;
  outline: none;
  background: linear-gradient(90deg, #0E2078 -0.3%, #112ED8 130.42%);
  border-radius: 8px;
  color: #fff;
  padding: 8px 18px;
  font-family: M PLUS 1;
  font-size: 16px;
  font-weight: 400;
  z-index: 10 !important;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

.rounded-checkbox {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  // vertical-align: middle;
  border: 1px solid rgb(212, 206, 206);
  background: #FFFFFF;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.rounded-checkbox:checked {
  appearance: auto;
  // clip-path: circle(50% at 50% 50%);
  clip-path: circle(48%);
  // background-color: #112BC2;
  background-color: #0b23a6;
}

.rounded-checkbox:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.tracking-modal {
  .modal-header {
    border-bottom: none !important;
  }
  .input-box {
    input {
      border: none;
      outline: none;
      border-bottom: 0.5px solid #ccc;
      padding: 10px 0;
    }
     ::placeholder {
      color: #2a2a2a !important;
    }
  }
}

.upload-box {
  background: #f2f6f9;
  // background: #FDFDFD;
  border-radius: 8px;
  h3,
  h6 {
    display: flex;
    justify-content: center;
    color: #949494;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
  }
  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 30px;
  }
}

.drag-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px 50px;
  border: 2px dashed rgba(148, 148, 148, 0.7);
  border-radius: 6px;
  span {
    position: relative;
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    pointer-events: none;
    padding-top: 10px;
    color: #6fb0eb;
    a {
      color: #1881e1;
    }
  }
  input {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.file-import {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f2f6f9;
  padding: 7px 9px;
  .file-importicon {
    width: 34px;
    min-width: 34px;
    height: 34px;
    margin: 5px;
    margin-right: 10px;
    &[data-src$=".pdf"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/><path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/></svg>') no-repeat;
    }
    &[data-src$=".png"],
    &[data-src$=".gif"],
    &[data-src$=".jpg"],
    &[data-src$=".jpeg"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/><path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/></svg>') no-repeat;
    }
    &[data-src$=".doc"],
    &[data-src$=".docx"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" class="bi bi-file-earmark-word" viewBox="0 0 16 16"><path d="M5.485 6.879a.5.5 0 1 0-.97.242l1.5 6a.5.5 0 0 0 .967.01L8 9.402l1.018 3.73a.5.5 0 0 0 .967-.01l1.5-6a.5.5 0 0 0-.97-.242l-1.036 4.144-.997-3.655a.5.5 0 0 0-.964 0l-.997 3.655L5.485 6.88z"/><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/></svg>') no-repeat;
    }
  }
  span {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    color: #6a738d;
    font-weight: bold;
    &:hover {
      color: blue;
    }
    &:active {
      color: green;
    }
  }
  .icon {
    cursor: pointer;
    &:hover {
      color: blue;
    }
    &:active {
      color: green;
    }
  }
}

.upload-btn {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

// login input css
.login-input {
  width: 100%;
  box-shadow: 0px 0px 8px 0px #00000040;
  border: none;
  border-radius: 8px;
  margin: 8px 0;
  outline: none;
  padding: 16px;
  box-sizing: border-box;
  transition: 0.3s;
  border-left: 10px solid #454E8F;
}

.login-input:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.inputWithIcon .login-input {
  padding-left: 60px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  left: 0;
  top: 24px;
  padding-left: 20px;
  padding-right: 10px;
  color: #aaa;
  transition: 0.3s;
  border-right: 1px solid #2D6192;
}

.w-auto {
  width: auto !important;
}

.inputWithIcon .login-input:focus+i {
  color: dodgerBlue;
  border-color: dodgerBlue;
}

.inputWithIcon.inputIconBg .login-input:focus+i {
  color: #fff;
  background-color: dodgerBlue;
}

.vision {
  position: absolute;
  top: 23px;
  right: 10px;
  border: none;
  background: none;
  .icon {
    color: #515456;
  }
}

.btn-line {
  background: none;
  padding: 0;
  border: none;
  text-decoration: underline;
  color: #5073b6;
  font-weight: 700;
  font-size: 20px;
  &:hover {
    color: #0c1faa;
    transition: 500ms;
  }
}

.btn-line:disabled {
  color: #a7a7a7;
}

.datepicker {
  z-index: 9999 !important
}

.upload-btn:disabled {
  background: #a7a7a7;
  cursor: initial;
}

.eye-btn2 {
  button {
    background: transparent;
    &:hover {
      color: #1881e1;
    }
  }
}

.br-left {
  border-left: 1px solid black;
}

.br-right {
  border-right: 1px solid black;
}

.new-tbl {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    td,
    th {
      text-align: start;
      padding: 12px 10px;
    }
    th {
      background-color: #F6F6F6;
    }
    td {
      padding: 10px 10px;
    }
  }
  tr:nth-child(odd) {
    background-color: #FBFBFB;
    border: 1px solid #E2E2E2;
  }
}